export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>The management team of the company is composed of an appointed general manager and all the shareholders of the company. The company has highly qualified staff who are capable of delivering high quality service. Some of the staffs are certified by Microsoft and Cisco. The Engineers have strong experience in supporting Microsoft BackOffice applications (Microsoft Exchange Server, SQL server, Internet Security and Acceleration server, (ISA) Internet Information server, (IIS), MDaemon and Oracle.
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
